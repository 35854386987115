import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: colors.grey.darken3,
        secondary: colors.grey.darken4,
        accent: colors.blueGrey.base,
        error: colors.red.base,
        warning: colors.pink.base,
        info: colors.blueGrey.base,
        success: colors.green.base
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})
