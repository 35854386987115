<template>
  <v-snackbar
    v-model="status"
    top
    multi-line
    :timeout="-1"
    color="warning"
  >
    Error:
    {{ message }}
  </v-snackbar>
</template>
<script>
export default {
  computed: {
    status () {
      return this.$store.state.error.status
    },
    message () {
      return this.$store.state.error.message
    }
  }

}
</script>
