import { SECRET } from './config'
const jwt = require('jsonwebtoken')

export const signJWT = user => {
  const token = jwt.sign(user, SECRET, {
    expiresIn: '30d'
  })
  return token
}

export const verifyJWT = async token => {
  try {
    const validToken = await jwt.verify(token, SECRET)
    return {
      status: 'ok',
      user: validToken
    }
  } catch (e) {
    return {
      status: 'error',
      user: {}
    }
  }
}
